import React, { useState, useEffect } from "react";
import { MicrosoftLoginForm } from "./MicrosoftLogin";
import { RemoveAllCookies } from "../../JS/Common";
import { LazyLoadImage } from "react-lazy-load-image-component";
import GoogleOauth from "../../Components/GoogleOauth";
import { Carousel } from "react-bootstrap";

export function Login() {
  const [AlertMsgs, setAlertMsgs] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  useEffect(() => {
    RemoveAllCookies();
  }, []);

  const slides = [
    {
      image: "/images/LoginSlider/LoginSlider1.svg",
      caption: "Everything you need, all at no cost.",
    },
    {
      image: "/images/LoginSlider/LoginSlider2.svg",
      caption: "Free Help Desk with advanced Reasoning.",
    },
    {
      image: "/images/LoginSlider/LoginSlider3.svg",
      caption: "Audit your inventory with instant reports.",
    },
    {
      image: "/images/LoginSlider/LoginSlider4.svg",
      caption: "Create custom parental coverage plans.",
    },
    {
      image: "/images/LoginSlider/LoginSlider5.svg",
      caption: "New ‘elevated tech’ role.",
    },
  ];

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <React.Fragment>
      <LazyLoadImage
        src="/images/LoginBg.png"
        alt=""
        className="MainDiv-bg-image"
      />
      <div className="position-relative">
        <div className="login-container">
          <div className="row h-100 align-items-center">
            <div className="col-md-5 col-12 px-0 position-relative">
              <div className="d-flex flex-column p-3 justify-content-center align-items-center px-2 card-wrapper">
                <div className="col-12 text-center">
                  <div
                    style={{ color: `${AlertColor}` }}
                    className="font-16"
                    dangerouslySetInnerHTML={{ __html: AlertMsgs }}
                  />
                </div>
                <div className="my-4 px-3 text-center">
                  <img
                    src="/images/K-12Logo.svg"
                    className="img-fluid w-75"
                    alt="Logo"
                  />
                </div>
                <h2 className="mb-1 fw-semibold mt-2">Welcome Back!</h2>
                <label className="mb-4 font-13">
                  New to Rocket?
                  <a
                    className="ps-2 cursor-pointer"
                    style={{ color: "rgb(30 191 162)" }}
                    href="/register"
                  >
                    Create an account, it's free.
                  </a>
                </label>
                <div className="col-md-10 text-center mt-3">
                  <GoogleOauth
                    alertmsg={setAlertMsgs}
                    alertcolor={setAlertColor}
                    flag="signin"
                  />
                </div>
                <div className="col-12 text-center py-3">
                  <img src="/images/NewLoginOr.svg" className="img-fluid" />
                </div>
                <div className="col-md-10 text-center">
                  <MicrosoftLoginForm
                    alertmsg={setAlertMsgs}
                    alertcolor={setAlertColor}
                  />
                </div>
                <div className="row pt-2">
                  <div className="col-md-6 ps-0  text-end text-nowrap">
                    <a
                      className="ps-2 cursor-pointer font-13"
                      style={{ color: "rgb(30 191 162)" }}
                      href="https://www.k12techrepairs.com/system-status/new-features"
                      target="_blank"
                    >
                      View System Status
                    </a>
                  </div>
                  <div className="col-md-6  text-start ps-4 text-nowrap">
                    <a
                      className="cursor-pointer font-13"
                      style={{ color: "rgb(30 191 162)" }}
                      href="https://k12tech.clickmeeting.com/k12tech-rocket-demo/register"
                      target="_blank"
                    >
                      Join a Live Demo
                    </a>
                  </div>
                  <div className="col-12 text-center mt-5 font-13">
                    By proceeding, you agree to the <br />
                    <a
                      className="cursor-pointer"
                      style={{ color: "black", textDecoration: "underline" }}
                      href="/terms-of-service"
                      target="_blank"
                    >Terms of Service</a> and &nbsp;
                    <a
                      className="cursor-pointer"
                      style={{ color: "black", textDecoration: "underline" }}
                      href="/privacy-policy"
                      target="_blank"
                    >Privacy Policy.</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 col-12 px-0 position-relative">
              <Carousel
                activeIndex={index}
                onSelect={handleSelect}
                controls={false}
                indicators={false}
              >
                {slides.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex flex-column align-items-center w-100">
                      <img src={item.image} className="img-fluid w-100" alt={`${item.image}`} />
                      <p className="m-0 p-0 fs-4 fw-semibold text-center text-nowrap w-75">
                        {item.caption}
                      </p>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="custom-indicators">
                {[0, 1, 2, 3, 4].map((idx) => (
                  <span
                    key={idx}
                    className={`carousel-dot-css ${index === idx ? "active" : ""
                      }`}
                    onClick={() => setIndex(idx)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
