import React, { Fragment, useEffect, useRef, useState } from 'react'
import { CamelCaseWithSpaces, Camelcase, LogInSchoolID, LogInUserID, MMDDYYYY, formatDateToMMDDYYYY, getUrlParameter, handleKeyPress, typewatch } from '../../JS/Common';
import { ApiGetCall, ApiGetCallAuthKey, ApiGetHeaders, ApiPostCall } from '../../JS/Connector';
import { saveAs } from 'file-saver'
import { CheckValidation } from '../../Components/Validations';
import AlertsComp from '../../Components/AlertsComp';
import Pagination from 'react-js-pagination';
import CustomizationPopup from '../../Components/CustomizationPopup';
import UserDetailsPopup from '../../Components/UserDetailsPopup';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faSatelliteDish, faMoneyCheckDollar, faStamp } from '@fortawesome/free-solid-svg-icons';
import { DeviceDetailsPopup } from '../../Components/DeviceDetailsPopup';
import TicketDetailsPopup from '../../Components/TicketDetailsPopup';
import SignatureCanvas from "react-signature-canvas";
import jinja from "jinja-js";
import html2pdf from "html2pdf.js";
import Cookies from "js-cookie";
import PageHead from '../../Components/PageHead';
import Tab from '../../Components/Tab';
import Popup from '../../Components/Popup';
import AlertPopup from '../../Components/AlertPopup';
export function SchoolInvoice() {
    const BaseUrl = process.env.REACT_APP_Base_URL;
    const [setSchoolInvoiceData, setAllInvoiceData] = useState([]);
    const [CloseTicketData, setClosedTicketData] = useState([]);
    const [InvoiceModal, setInvoiceModal] = useState(false);
    const [checkno, setCheckno] = useState('');
    const [Alerts, setAlerts] = useState("");
    const [SearchStringVal, setSearchStringVal] = useState({
        SearchString: '',
        SortbyFlag: 'desc',
        SearchStringForCloseTicket: '',
        SortbyFlagForCloseTicket: 'desc'
    });
    const [BatchAndInvoiceId, setBatchAndInvoiceId] = useState({
        BatchId: '',
        InvoiceNum: '',
        BatchName: '',
        BatchType: ''
    })
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageRangeCount, setpageRangeCount] = useState(0);
    const [LimitForPagination, setLimitForPagination] = useState(25);
    const [ChequeNoFlag, setChequeNoFlag] = useState('');
    const [GridTabs, setGridTabs] = useState({
        CloseTicketTab: "",
        BatchesTabs: "active",
        PastBatchesTabs: '',
        ResolvedTicketTab: ''
    });
    const showPagination = setSchoolInvoiceData.length > 0;
    var BucketS3Link = process.env.REACT_APP_CDN_KEY;
    const navigate = useNavigate()
    const [currentPage1, setCurrentPage1] = useState(1);
    const [LimitForPagination1, setLimitForPagination1] = useState(25);
    const [pageRangeCount1, setpageRangeCount1] = useState(0);
    const showPagination1 = CloseTicketData.length > 0;
    var MoreDueFlag = getUrlParameter("flag");
    const [CustomizationModal, setCustomizationModal] = useState(false)
    const [columnNamesForBilling, setColumnNamesForBilling] = useState([]);
    const [columnNamesForCloseTickets, setColumnNamesForCloseTickets] = useState([]);
    const [UserModal, setUserModal] = useState(false)
    const [Userid, setUserid] = useState('');
    const [ReponseForLink, setReponseForLink] = useState([]);
    const [ShowCreateBatchButton, setShowCreateBatchButton] = useState(false);
    const [ShowResolvedbtn, setShowResolvedbtn] = useState(false);
    const [ShowGenerateInvoicebtn, setShowGenerateInvoicebtn] = useState(false);
    const [ShowResolvedTicketsbtn, setShowResolvedTicketsbtn] = useState(false);
    const [ReceiveBatchModal, setReceiveBatchModal] = useState(false);
    const [ResolveTicketModal, setResolveTicketModal] = useState(false);
    const [PaymentModal, setPaymentModal] = useState(false);
    const [ResolvedModal, setResolvedModal] = useState(false);
    const [selectedIdsAll, setSelectedIdsAll] = useState([]);
    const [SelectedIdsForPending, setSelectedIdsForPending] = useState([]);
    const [SelectedIdsForResolved, setSelectedIdsForResolved] = useState([]);
    const [SelectedItems, setSelectedItems] = useState([]);
    const [SelectedItemsForPending, setSelectedItemsForPending] = useState([]);
    const sigCanvasForReceivedBy = useRef({});
    const sigCanvasForReceivedByFromGrid = useRef({});
    const [SignatureDataForReceivedBy, setSignatureDataForReceivedBy] = useState('')
    const [SignatureDataForReceivedByGrid, setSignatureDataForReceivedByGrid] = useState('')
    const [isModal, setisModal] = useState({
        isshowpopup: false,
        isTicketDetailspopup: false,
    });
    const [TicketParams, setTicketParams] = useState({
        inventoryId: "",
        ticketId: "",
        Chargeabilityflag: ''
    });
    var SchoolId = LogInSchoolID;
    const location = useLocation();
    const FlagForCancel = location.state && location.state.cancelflag;
    const [SortKeyForBatches, setSortKeyForBatches] = useState(null)
    const [SortKeyForTickets, setSortKeyForTickets] = useState(null)
    const [lastChecked, setLastChecked] = useState(null);
    const [BuildingArray, setBuildingArray] = useState([]);
    const [selectedBuilding, setselectedBuilding] = useState('');
    useEffect(() => {
        if (GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active') {
            ApiGetHeaders(16, setColumnNamesForCloseTickets)
        } else if (GridTabs.BatchesTabs == 'active' || GridTabs.PastBatchesTabs == 'active') {
            ApiGetHeaders(17, setColumnNamesForBilling)
        }
        GetBuildingGrade();
    }, []);
    useEffect(() => {
        const selectedArray = [
            ...selectedIdsAll.map((id) => ({ id })),
        ];
        const idAndSubtotalArray = selectedArray.map(({ id }) => ({ id: id }));
        setSelectedItems(idAndSubtotalArray);
        if (idAndSubtotalArray.length === 0) {
            setShowCreateBatchButton(false)
        }
    }, [selectedIdsAll])
    useEffect(() => {
        const selectedArray1 = [
            ...SelectedIdsForPending.map((id) => ({ id })),
        ];
        const idAndSubtotalArray1 = selectedArray1.map(({ id }) => ({ ID: id }));
        setSelectedItemsForPending(idAndSubtotalArray1);
        if (idAndSubtotalArray1.length === 0) {
            setShowResolvedbtn(false)
            setShowGenerateInvoicebtn(false)
        }
    }, [SelectedIdsForPending])

    useEffect(() => {
        if (SelectedIdsForResolved.length === 0) {
            setShowResolvedTicketsbtn(false);
        }
    }, [SelectedIdsForResolved]);

    useEffect(() => {
        if (GridTabs.BatchesTabs == 'active') {
            GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, SortKeyForBatches ? SortKeyForBatches : null, 2);
        } else if (GridTabs.PastBatchesTabs == 'active') {
            GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, SortKeyForBatches ? SortKeyForBatches : null, 3);
        } else if (GridTabs.CloseTicketTab == 'active') {
            GetClosedTicketData(SearchStringVal.SearchStringForCloseTicket ? SearchStringVal.SearchStringForCloseTicket : null, SortKeyForTickets ? SortKeyForTickets : null, 1, selectedBuilding)
        } else {
            GetClosedTicketData(SearchStringVal.SearchStringForCloseTicket ? SearchStringVal.SearchStringForCloseTicket : null, SortKeyForTickets ? SortKeyForTickets : null, 2, selectedBuilding)
        }
        if (MoreDueFlag == 1) {
            if (GridTabs.BatchesTabs == 'active') {
                setGridTabs({ ...GridTabs, BatchesTabs: "active", CloseTicketTab: "", PastBatchesTabs: '', ResolvedTicketTab: '' });
                GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, null, 2);
            } else {
                setGridTabs({ ...GridTabs, BatchesTabs: "", CloseTicketTab: "", PastBatchesTabs: 'active', ResolvedTicketTab: '' });
                GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, null, 3);
            }
        }
        if (FlagForCancel == 1) {
            setGridTabs({ ...GridTabs, CloseTicketTab: "active", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: '' });
            GetClosedTicketData(null, null, 1, null)
            ApiGetHeaders(16, setColumnNamesForCloseTickets)
            setSearchStringVal({ ...SearchStringVal, SearchStringForCloseTicket: '', SearchString: '' })
        }
    }, [currentPage, LimitForPagination, currentPage1, LimitForPagination1, selectedBuilding]);
    async function GetSchoolInvoiceData(searchstring, sortkey, flag) {
        if (!searchstring) searchstring = null;
        if (sortkey) SearchStringVal.SortbyFlag = SearchStringVal.SortbyFlag == "asc" ? "desc" : "asc";
        var GridFlagForBilling = null
        if (flag == 2) {
            GridFlagForBilling = 17
        } else {
            GridFlagForBilling = 38
        }
        var joinedString = null;
        if (columnNamesForBilling.length > 0) {
            var joinedString = columnNamesForBilling.join('~');
        }
        typewatch(async function () {
            setLoading(true);
            await ApiGetCall("/AllBatchData/" + LogInSchoolID + "&" + searchstring + "&" + sortkey + "&" + SearchStringVal.SortbyFlag + '&' + currentPage + '&' + LimitForPagination + '&' + GridFlagForBilling + "?data=" + joinedString).then((result) => {
                if (result === undefined || result === "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount(responseRs.total)
                    setAllInvoiceData(responseRs.data.length ? responseRs.data : [])
                    setLoading(false);
                }
            });
        }, searchstring != null ? 500 : 0);
    }
    async function GetBuildingGrade() {
        await ApiGetCallAuthKey("/allGradeandBuilding/" + LogInSchoolID).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    setBuildingArray(responseRs.building);
                }
            }
        });
    }
    async function GetClosedTicketData(searchString, skey, flag, building) {
        if (!searchString) searchString = null;
        if (skey) SearchStringVal.SortbyFlagForCloseTicket = SearchStringVal.SortbyFlagForCloseTicket == "asc" ? "desc" : "asc";
        var GridFlag = null;
        if (flag == 1) {
            GridFlag = 16;
        } else {
            GridFlag = 39
        }
        setselectedBuilding(building)

        typewatch(async function () {
            setLoading(true)
            await ApiGetCall("/allClosedTicket/" + LogInSchoolID + '&' + searchString + '&' + skey + '&' + SearchStringVal.SortbyFlagForCloseTicket + '&' + GridFlag + '&' + currentPage1 + '&' + LimitForPagination1 + '&' + building).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    const responseRs = JSON.parse(result);
                    setpageRangeCount1(responseRs.msg.total)
                    setClosedTicketData(responseRs.msg.data.length ? responseRs.msg.data : [])
                    setLoading(false);
                }
            });
        }, searchString != null ? 500 : 0);
    }
    async function downloadReceipt(InvoiceNumber) {
        await ApiGetCall("/downloadReceipt/" + InvoiceNumber).then((result) => {
            if (result == undefined || result == "") {
                setAlerts(<AlertsComp show={true} variant="success" msg={"No Receipt Found"} />);
                setTimeout(function () {
                    setAlerts(<AlertsComp show={false} />)
                }, 1500);
            } else {
                const responseRs = result;
                if (responseRs.length != 0) {
                    saveAs(responseRs, 'image.jpg');
                }
                setLoading(false);
            }
        });
    };
    async function ExtraDocument(id) {
        await ApiGetCall("/ExtraAttachedDocForBatch/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                const responseRs = result;
                if (responseRs == "error") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"No extra attached document found"} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                    }, 3000);
                } else {
                    const link = document.createElement('a');
                    link.href = BucketS3Link + responseRs;
                    window.open(link.href, '_blank');
                }
            }
        });
    };
    async function SubmitpaymentDetails() {
        var isFormValid = CheckValidation({ formID: 'batchNameRequired' });
        if (!isFormValid) return;
        var signatureBase64 = null;
        if (SignatureDataForReceivedByGrid) {
            signatureBase64 = SignatureDataForReceivedByGrid
        } else {
            signatureBase64 = sigCanvasForReceivedByFromGrid?.current?.isEmpty() ? null : sigCanvasForReceivedByFromGrid?.current?.getTrimmedCanvas()?.toDataURL("image/png");
        }
        setLoading(true);
        var flagForOption = null
        if (ChequeNoFlag == 0 || ChequeNoFlag == 2) {
            flagForOption = 1
        } else if (ChequeNoFlag == 1) {
            flagForOption = 2
        } else {
            flagForOption = 1
        }
        var raw = JSON.stringify({
            batchId: BatchAndInvoiceId.BatchId,
            invoiceId: BatchAndInvoiceId.InvoiceNum,
            chequeNo: checkno ? checkno : null,
            schoolId: LogInSchoolID,
            flag: flagForOption,
            UserID: LogInUserID,
            BatchReceiverSignature: signatureBase64
        });
        await ApiPostCall("/paymentDetailsSchoolSide", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setInvoiceModal(false);
                const responseRs = result;
                if (responseRs == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Payment Details Sent Successfully"} />);
                    setTimeout(function () {
                        setAlerts(<AlertsComp show={false} />)
                        var flag = GridTabs.BatchesTabs == 'active' ? 2 : 3;
                        GetSchoolInvoiceData(null, null, flag);
                    }, 1500);

                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        });
    }
    function renderContentForColumn(columnName, item) {
        const isActiveTab = GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active';

        const commonContentMap = {
            "created_at": isActiveTab ? (item.created_at ? formatDateToMMDDYYYY(item.created_at) : "-") : (MMDDYYYY(item.created_at) || '-'),
            "Notes": <div className="note-wrap">{item.notes || '-'}</div>
        };

        const activeTabContentMap = {
            "ticket_num": (
                <span
                    className="cursor-pointer"
                    title="Show Ticket Details"
                    onClick={(e) => {
                        setisModal({ ...isModal, isTicketDetailspopup: true });
                        setTicketParams({ ...TicketParams, ticketId: item.id, inventoryId: item.inventory_id });
                    }}
                >
                    <u>{item.ticket_num || '-'}</u>
                </span>
            ),
            "Serial_number": (
                <span
                    className="cursor-pointer"
                    title="Show Device Details"
                    onClick={(e) => {
                        setisModal({ ...isModal, isshowpopup: true });
                        setTicketParams({ ...TicketParams, inventoryId: item.inventory_id, ticketId: item.id });
                    }}
                >
                    <u>{item?.inventory_management?.serial_number || "-"}</u>
                </span>
            ),
            "User": item?.inventory_management?.student_inventory?.student?.device_user_first_name ? (
                <u
                    onClick={(e) => {
                        setUserid(item?.inventory_management?.student_inventory?.student?.id);
                        setUserModal(true);
                    }}
                    className="cursor-pointer"
                >
                    {Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_first_name) + ' ' + (Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_middle_name || ' ')) + ' ' + Camelcase(item?.inventory_management?.student_inventory?.student?.device_user_last_name)}
                </u>
            ) : '-',
            "Parent_Guardian_Email": item?.inventory_management?.student_inventory?.student?.parent_guardian_email || '-',
            "Parent_phone_number": item?.inventory_management?.student_inventory?.student?.parent_phone_number || '-',
            "Parent_guardian_name": item?.inventory_management?.student_inventory?.student?.parent_guardian_name || '-',
            "Building": item?.inventory_management?.building_name?.name || '-',
            "Device_model": item?.inventory_management?.device_model || '-',
            "Grade": item?.inventory_management?.student_inventory?.student?.grade || '-',
            "Student_num": item?.inventory_management?.student_inventory?.student?.student_num || '-',
            "created_by": item?.user?.first_name + ' ' + item?.user?.last_name || "-",
            "return_location": item?.return_location?.name || '-'
        };

        const inactiveTabContentMap = {
            "Name": (
                <span
                    className="cursor-pointer"
                    title='Show Batch Details'
                    onClick={(e) => {
                        navigate('/ticket-history?id=' + item?.id, { state: { SchoolId, SchoolAdminFlag: 2 } })
                    }}
                >
                    <u>{item?.name || '-'}</u>
                </span>
            ),
            "InvoiceNum": item?.invoice[0]?.id == null ? <>-</> : <div>#{item?.invoice[0]?.id}</div>,
            "deviceCount": item.batch_log_count || '-',
            "Amount": item?.amount == null ? <>0</> : <div>${item?.amount}</div>,
            "Payment_Status": item?.invoice[0]?.payment_status == 1 ? (
                <span style={{ color: "#FF7076" }}>Pending</span>
            ) : item?.invoice[0]?.payment_status == 3 ? (
                <span style={{ color: "#3CBBA5" }}>Success</span>
            ) : (
                <span style={{ color: "#F37B22" }}>In Progress</span>
            )
        };

        const contentMap = isActiveTab ? { ...commonContentMap, ...activeTabContentMap } : { ...commonContentMap, ...inactiveTabContentMap };

        return contentMap[columnName] || null;
    }
    const handleSortClick = (item) => {
        if (GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active') {
            if (item == "User") {
                item = "Device_user_first_name";
            } else if (item == 'created_by') {
                item = "first_name"
            }
        } else {
            if (item == 'deviceCount') {
                item = 'batch_log_count'
            }
        }
        if (item !== 0) {
            if (GridTabs.CloseTicketTab == 'active') {
                GetClosedTicketData(SearchStringVal.SearchStringForCloseTicket, item, 1, selectedBuilding)
                setSortKeyForTickets(item)
            } else if (GridTabs.BatchesTabs == 'active') {
                GetSchoolInvoiceData(SearchStringVal.SearchString, item, 2)
                setSortKeyForBatches(item)
            } else if (GridTabs.PastBatchesTabs == 'active') {
                GetSchoolInvoiceData(SearchStringVal.SearchString, item, 3)
                setSortKeyForBatches(item)
            } else if (GridTabs.ResolvedTicketTab == 'active') {
                GetClosedTicketData(SearchStringVal.SearchStringForCloseTicket, item, 2, selectedBuilding)
                setSortKeyForTickets(item)
            }
        }
    };
    async function handleReopenTicket() {
        var id = TicketParams.ticketId;
        setLoading(true);
        await ApiPostCall("/updateResolvedToPending/" + id).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setResolveTicketModal(false);
                const responseRs = result;
                if (responseRs == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Ticket reopened successfully"} />);
                    setTimeout(function () {
                        setGridTabs({ ...GridTabs, CloseTicketTab: "active", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: '' });
                        GetClosedTicketData(null, null, 1, null)
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        })
    }
    async function PreviewInvoiceHandle(pdf, id) {
        if (pdf != null) {
            setLoading(true);
            await ApiPostCall("/downloadSchoolInvoicePdf/" + id).then((result) => {
                if (result == undefined || result == "") {
                    alert("Something went wrong");
                } else {
                    setLoading(false);
                    const responseRs = result;
                    if (responseRs == "success") {
                        window.open(BucketS3Link + pdf, '_blank')
                        setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: 'active' });
                        GetClosedTicketData(null, null, 2, null)
                    } else {
                        setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                        setTimeout(() => {
                            setAlerts(<AlertsComp show={false} />)
                        }, 1500);
                    }
                }
            })
        } else {
            setAlerts(<AlertsComp show={true} variant="success" msg={"No Invoice Found"} />);
            setTimeout(function () {
                setAlerts(<AlertsComp show={false} />)
            }, 1500);
        }
    }
    async function handlePayment() {
        var id = TicketParams.ticketId;
        setLoading(true);
        var raw = JSON.stringify({
            ticketId: id,
            schoolId: SchoolId
        })

        await ApiPostCall("/paidResolvedTicket", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setPaymentModal(false);
                const responseRs = result;
                if (responseRs == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Ticket mark as paid successfully"} />);
                    setTimeout(function () {
                        setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: 'active' });
                        GetClosedTicketData(null, null, 2, null)
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={responseRs.message} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
            }
        })

    }
    function handleHeaderCheckboxChange(e, ticketFlag) {
        setShowCreateBatchButton(true);
        const isChecked = e.target.checked;
        if (ticketFlag === "all") {
            setSelectedIdsAll(isChecked ? setSchoolInvoiceData.filter(item => item.type != 2 && item.type != null).map(item => item.id) : []);
        }
    }
    function handleCheckboxChange(e, ticketFlag, ID) {
        const isChecked = e.target.checked;
        setShowCreateBatchButton(true)
        if (ticketFlag == "all") {
            setSelectedIdsAll((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
    }
    function handleHeaderCheckboxForPending(e, ticketFlag) {
        setShowResolvedbtn(true);
        setShowGenerateInvoicebtn(true);
        const isChecked = e.target.checked;
        if (ticketFlag === "allticket") {
            setSelectedIdsForPending(isChecked ? CloseTicketData.map(item => item.id) : []);
        }
    }
    function handleHeaderCheckboxForResolved(e, ticketFlag) {
        setShowResolvedTicketsbtn(true);
        const isChecked = e.target.checked;
        if (ticketFlag === "resolved") {
            setSelectedIdsForResolved(isChecked ? CloseTicketData.map(item => item?.latestInvoice?.id) : []);
        }
    }
    function handleCheckboxForPending(e, ID) {
        const isChecked = e.target.checked;
        setShowResolvedbtn(true);
        setShowGenerateInvoicebtn(true);
        if (e.nativeEvent.shiftKey && lastChecked !== null) {
            const start = Math.min(lastChecked, ID);
            const end = Math.max(lastChecked, ID);

            setSelectedIdsForPending((prevIds) => {
                let newIds = [...prevIds];
                for (let i = start; i <= end; i++) {
                    if (isChecked && !newIds.includes(i)) {
                        newIds.push(i);
                    } else if (!isChecked && newIds.includes(i)) {
                        newIds = newIds.filter((id) => id !== i);
                    }
                }
                return newIds;
            });
        } else {
            setSelectedIdsForPending((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
        setLastChecked(ID);
    }
    function handleCheckboxForResolved(e, ticketFlag, ID) {
        const isChecked = e.target.checked;
        setShowResolvedTicketsbtn(true);
        if (ticketFlag == "resolved") {
            setSelectedIdsForResolved((prevIds) => {
                if (isChecked) {
                    return [...prevIds, ID];
                } else {
                    return prevIds.filter((id) => id !== ID);
                }
            });
        }
    }
    async function ReceiveBatchCall() {
        var signatureBase64 = null;
        signatureBase64 = sigCanvasForReceivedBy.current.isEmpty() ? null : sigCanvasForReceivedBy.current.getTrimmedCanvas().toDataURL("image/png");
        var raw = JSON.stringify({
            SchoolID: LogInSchoolID,
            BatchID: SelectedItems,
            Flag: 2,
            UserID: LogInUserID,
            BatchReceiverSignature: signatureBase64,
            BatchDeliveredSignature: null
        });
        setLoading(true)
        await ApiPostCall("/DeliverAndReciverBtnForBilling", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setReceiveBatchModal(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Status Change Successfully"} />);
                    setTimeout(() => {
                        GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, null, 2);
                        setSelectedIdsAll([])
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    async function ResolvedTicketCall() {
        var raw = JSON.stringify({
            TicketIDArray: SelectedItemsForPending,
            resolvedFlag: 1
        });
        setLoading(true)
        await ApiPostCall("/ChangeChargeability", raw).then((result) => {
            if (result == undefined || result == "") {
                alert("Something went wrong");
            } else {
                setLoading(false);
                setResolvedModal(false);
                if (result == "success") {
                    setAlerts(<AlertsComp show={true} variant="success" msg={"Tickets has been successfully resolved"} />);
                    setTimeout(() => {
                        setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: 'active' });
                        GetClosedTicketData(null, null, 2, null)
                        setSelectedIdsForPending([])
                        setAlerts(<AlertsComp show={false} />);
                    }, 1500);
                }
                else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={result} />);
                }
            }
        });
    }
    const clearSignatureForReceivedBy = () => {
        if (SignatureDataForReceivedBy != null) {
            setSignatureDataForReceivedBy(null)
        } else {
            sigCanvasForReceivedBy.current.clear();
        }
    };
    const clearSignatureForReceivedByForGrid = () => {
        if (SignatureDataForReceivedByGrid != null) {
            setSignatureDataForReceivedByGrid(null)
        } else {
            sigCanvasForReceivedByFromGrid.current.clear();
        }
    };
    function PrintPackingList(batchid) {
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true)
        fetch('PackingListForAdminBatch.html')
            .then(response => response.text())
            .then(templateText => jinja.compile(templateText))
            .then(template => {
                fetch(BaseUrl + "/printlistForBillingBatch/" + batchid, { headers: myHeaders })
                    .then(response => response.json())
                    .then(data => {
                        const formattedDate = new Date(data.created_at).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                        });
                        const rendered = template.render({ data, formattedDate });
                        const pdfContent = document.createElement('div');
                        pdfContent.innerHTML = rendered;
                        pdfContent.style.padding = '20px';
                        html2pdf().from(pdfContent).save();
                        setLoading(false)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.error('Error loading data from API', error);
                    });
            })
            .catch(error => {
                setLoading(false)
                console.error('Error loading template', error);
            });
    }
    async function downloadResolvedInvoice() {
        var ciphertext = Cookies.get('ciphertext');
        var myHeaders = new Headers();
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Authorization", ciphertext);
        setLoading(true);
        var vArray = [];
        SelectedIdsForResolved.map((id) =>
            vArray.push(id)
        )
        var FinalArray = vArray.map(item => String(item));
        const formdata = new FormData();
        formdata.append('schoolid', SchoolId);
        formdata.append('id', JSON.stringify(FinalArray));
        formdata.append('flag', 1);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow",
        };
        fetch(`${BaseUrl}/createInvoiceForMultipleTicket`, requestOptions).then(response => response.text())
            .then(result => {
                const responseRs = JSON.parse(result);
                if (responseRs.response == "success") {
                    const CDNkey = process.env.REACT_APP_CDN_KEY;
                    const pdfUrl = CDNkey + responseRs.path;
                    setTimeout(() => {
                        setSelectedIdsForResolved([]);
                    }, 1500);
                    window.open(pdfUrl, '_blank');
                } else {
                    setAlerts(<AlertsComp show={true} variant="danger" msg={"Failed to download invoice."} />);
                    setTimeout(() => {
                        setAlerts(<AlertsComp show={false} />)
                    }, 1500);
                }
                setLoading(false);
            })
            .catch(error => console.log('error', error));
    }
    function handleSearchChange(e) {
        const searchValue = e.target.value || null;
        setCurrentPage(1);

        if (GridTabs.CloseTicketTab == 'active') {
            GetClosedTicketData(searchValue, null, 1, selectedBuilding);
        } else if (GridTabs.BatchesTabs == 'active') {
            GetSchoolInvoiceData(searchValue, null, 2);
        } else if (GridTabs.PastBatchesTabs == 'active') {
            GetSchoolInvoiceData(searchValue, null, 3);
        } else {
            GetClosedTicketData(searchValue, null, 2, selectedBuilding);
        }

        const searchStringKey = (GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active') ? 'SearchStringForCloseTicket' : 'SearchString';
        setSearchStringVal({ ...SearchStringVal, [searchStringKey]: searchValue });
    }
    function handleTabClick(tabType) {
        if (tabType == "currentbatch") {
            setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "active", PastBatchesTabs: '', ResolvedTicketTab: '' });
            GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, null, 2);
            if (columnNamesForBilling.length == 0) {
                ApiGetHeaders(17, setColumnNamesForBilling)
            }
            ApiGetHeaders(17, setColumnNamesForBilling)
            setSearchStringVal({ ...SearchStringVal, SearchStringForCloseTicket: '', SearchString: '' })
            setSelectedIdsForPending([])
            setSelectedIdsForResolved([])
            setSelectedIdsAll([])
        } else if (tabType == "pastbatch") {
            setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "", PastBatchesTabs: 'active', ResolvedTicketTab: '' });
            GetSchoolInvoiceData(SearchStringVal.SearchString ? SearchStringVal.SearchString : null, null, 3);
            if (columnNamesForBilling.length == 0) {
                ApiGetHeaders(17, setColumnNamesForBilling)
            }
            setSearchStringVal({ ...SearchStringVal, SearchStringForCloseTicket: '', SearchString: '' })
            setShowCreateBatchButton(false)
            setSelectedIdsForPending([])
            setSelectedIdsForResolved([])
            setSelectedIdsAll([])
        } else if (tabType == "ticketinvoice") {
            setGridTabs({ ...GridTabs, CloseTicketTab: "active", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: '' });
            GetClosedTicketData(null, null, 1, null)
            if (columnNamesForCloseTickets.length == 0) {
                ApiGetHeaders(16, setColumnNamesForCloseTickets)
            }
            setSearchStringVal({ ...SearchStringVal, SearchStringForCloseTicket: '', SearchString: '' })
            setShowCreateBatchButton(false)
            setSelectedIdsForPending([])
            setSelectedIdsForResolved([])
            setSelectedIdsAll([])
        } else if (tabType == "resolved") {
            setGridTabs({ ...GridTabs, CloseTicketTab: "", BatchesTabs: "", PastBatchesTabs: '', ResolvedTicketTab: 'active' });
            GetClosedTicketData(null, null, 2, null)
            if (columnNamesForCloseTickets.length == 0) {
                ApiGetHeaders(16, setColumnNamesForCloseTickets)
            }
            setSearchStringVal({ ...SearchStringVal, SearchStringForCloseTicket: '', SearchString: '' })
            setShowCreateBatchButton(false)
            setSelectedIdsForPending([])
            setSelectedIdsForResolved([])
            setSelectedIdsAll([])
        }
    }
    const ReceiveModalBody = (
        <div className="parent" id="batchNameRequired">
            <div className='col-12 text-justify px-1'>Marking a batch as paid from any of the options below will mean that you have paid K-12 Tech invoice generated for this batch and all the tickets under this batch will be marked as Closed.</div>
            <div className="col-md-12 align-item-center justify-content-between py-3">
                <div className="form-check pt-2">
                    <input className="form-check-input" checked={ChequeNoFlag == 2} type="radio" name='ParentalCoverage' value='0' id="No" onChange={(e) => setChequeNoFlag(2)} />
                    <label className="form-check-label">
                        Mark as Paid
                    </label>
                </div>
                <div className="form-check pt-2">
                    <input className="form-check-input" checked={ChequeNoFlag == 1} type="radio" name='ParentalCoverage' value='1' id="Yes" onChange={(e) => setChequeNoFlag(1)} />
                    <label className="form-check-label">
                        Mark as Paid with Cheque Number
                    </label>
                </div>
                <div className="form-check pt-2">
                    <input className="form-check-input" checked={ChequeNoFlag == 3} type="radio" name='ParentalCoverage' value='1' id="Yes" onChange={(e) => setChequeNoFlag(3)} />
                    <label className="form-check-label">
                        Pay online and Mark as Paid
                    </label>
                </div>

            </div>
            {ChequeNoFlag == 1 && (
                <div className=' col-12  mt-2 px-1'>
                    <input type="text" autoComplete='off' name='partName' className="form-control partNamee" required value={checkno}
                        placeholder='Enter Your Cheque Number *' onChange={(e) => setCheckno(e.target.value)} onKeyPress={handleKeyPress} />
                    <span className="form-text invalid-feedback" >
                        *required
                    </span>
                </div>
            )}
            {ChequeNoFlag == 3 && (
                <div>
                    <div className='ps-4'>
                        {ReponseForLink ?
                            <>
                                <a href={ReponseForLink} onClick={(e) => { e.preventDefault(); window.open(ReponseForLink, '_blank'); }}>
                                    {ReponseForLink}
                                </a>
                                <div className='pt-2'>Above is the invoice link for this batch, please click on link to Pay online in a new tab. After successful payment, you can click Send Notification button below.</div>
                            </>
                            :
                            <div>No Link Found</div>
                        }
                    </div>

                </div>
            )}
            <div className="greyBox mt-4">
                <div className='fw-600 font-15 text-center'>Received By Signature:</div>
                <div className='mt-2 text-center'>
                    {SignatureDataForReceivedByGrid ? (
                        <img src={SignatureDataForReceivedByGrid} alt="Received By Signature" />
                    ) : (
                        <SignatureCanvas
                            ref={sigCanvasForReceivedByFromGrid}
                            canvasProps={{ width: 430, height: 100, className: "signatureCanvas" }}
                        />
                    )}
                </div>
                <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForReceivedByForGrid}><u>Clear</u></label></div>
            </div>
        </div>
    )

    function handleGenerateInvoice() {
        navigate('/CloseTicketInvoice', { state: { tickets: SelectedItemsForPending } })
    }
    return (
        <Fragment>
            <PageHead LimitForPagination={LimitForPagination} setLimitForPagination={GridTabs.BatchesTabs == 'active' || GridTabs.PastBatchesTabs == 'active' ? setLimitForPagination : setLimitForPagination1}
                setCurrentPage={GridTabs.BatchesTabs == 'active' || GridTabs.PastBatchesTabs == 'active' ? setCurrentPage : setCurrentPage1} heading={"Billing"} handleSearch={handleSearchChange}
                loading={loading} Alerts={Alerts} showsearch={true} />
            <section>
                <div className="GridBox mt-2 p-3">
                    <div className="row align-items-center mb-3">
                        <div className="col-12 px-0">
                            <ul className="nav nav-tabs px-3">
                                <Tab isActive={GridTabs.BatchesTabs == 'active'}
                                    label="Current Batches" onClick={() => handleTabClick('currentbatch')} col={'col-md-2'} />
                                <Tab isActive={GridTabs.PastBatchesTabs == 'active'}
                                    label="Past Batches" onClick={() => handleTabClick('pastbatch')} col={'col-md-2'} />
                                <Tab isActive={GridTabs.CloseTicketTab == 'active'}
                                    label="Ticket Invoicing" onClick={() => handleTabClick('ticketinvoice')} col={'col-md-2'} />
                                <Tab isActive={GridTabs.ResolvedTicketTab == 'active'}
                                    label="Resolved Tickets" onClick={() => handleTabClick('resolved')} col={'col-md-2'} />
                            </ul>
                        </div>
                        <div className='col-md-12 d-flex align-items-center'>
                            <div className='col-md-7 redText font-11 pt-3'>
                                {GridTabs.BatchesTabs == 'active' && (
                                    <div className='ps-2'>
                                        <label>* The payment is currently being processed.</label><br />
                                    </div>
                                )}
                                {GridTabs.CloseTicketTab == 'active' && (
                                    <div className='theme-colorcode ps-2'>
                                        <label>*Billable Tickets</label>
                                    </div>
                                )}
                                {GridTabs.ResolvedTicketTab == 'active' && (
                                    <>
                                        <FontAwesomeIcon icon={faStamp} style={{ height: '20px', color: '#db3b3b' }} className='ps-2' /> Unpaid
                                        <FontAwesomeIcon icon={faStamp} style={{ height: '20px', color: '#2cc3a9' }} className='ps-3' /><label className='theme-colorcode ps-2'>Paid</label>
                                    </>
                                )}
                            </div>
                            {GridTabs.BatchesTabs == 'active' && (
                                <div className="col-md-5 text-end px-0 mx-0">
                                    {ShowCreateBatchButton == true && (
                                        <label className="BorderBtn text-end  mt-0" onClick={() => setReceiveBatchModal(true)}><FontAwesomeIcon icon={faSatelliteDish} className="pe-2" />Receive
                                        </label>
                                    )}
                                </div>
                            )}
                            {GridTabs.CloseTicketTab == 'active' && (
                                <div className="col-md-5 text-end pt-2 px-0 mx-0">
                                    <div className="col-6 offset-md-6 py-1 pe-0">
                                        <select className="FilterBorder" onChange={(e) => setselectedBuilding(e.target.value == 0 ? null : e.target.value)}>
                                            <option value="0">Filter by Building</option>
                                            {BuildingArray.map((item, j) => (
                                                <option value={item.ID} key={j}>
                                                    {item.building}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {ShowGenerateInvoicebtn == true && (
                                        <label className="BorderBtn text-end  mt-0" onClick={() => handleGenerateInvoice()}>Generate Invoice </label>
                                    )}
                                    {ShowResolvedbtn == true && (
                                        <label className="BorderBtn text-end  mt-0" onClick={() => setResolvedModal(true)}>Resolved </label>
                                    )}

                                </div>
                            )}
                            {GridTabs.ResolvedTicketTab == 'active' && (
                                <div className="col-md-5 text-end px-0 mx-0 pt-2">
                                    <div className="col-6 offset-md-6 py-1 pe-0">
                                        <select className="FilterBorder" onChange={(e) => setselectedBuilding(e.target.value)}>
                                            <option value="0">Filter by Building</option>
                                            {BuildingArray.map((item, j) => (
                                                <option value={item.ID} key={j}>
                                                    {item.building}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {ShowResolvedTicketsbtn == true && (
                                        <label className="BorderBtn  text-center me-0 mt-0" onClick={downloadResolvedInvoice}>Download All Invoices </label>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>


                    <div className="greyBox p-3">
                        {GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active' ?
                            <>
                                <div className="data-table-container">
                                    <table className="table data-table">
                                        <thead className='GridHeader'>
                                            <tr>
                                                {GridTabs.CloseTicketTab == 'active' && CloseTicketData?.length != 0 && (
                                                    <th scope="col"> <input className='form-check-input' type="checkbox" checked={SelectedIdsForPending.length == CloseTicketData.length} onChange={(e) => handleHeaderCheckboxForPending(e, "allticket")} /></th>
                                                )}

                                                {GridTabs.ResolvedTicketTab == 'active' && CloseTicketData?.length != 0 && (
                                                    <th scope="col">
                                                        <input className='form-check-input' type="checkbox" checked={SelectedIdsForResolved.length == CloseTicketData.length} onChange={(e) => handleHeaderCheckboxForResolved(e, "resolved")} />
                                                    </th>
                                                )}

                                                {columnNamesForCloseTickets?.map((item) => {
                                                    const columnName = item.replace(/_/g, ' ');
                                                    return columnName != 'Parent phone number' && columnName != 'created by' ?
                                                        <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                            {CamelCaseWithSpaces(columnName)}
                                                            <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                                        </th>
                                                        :
                                                        <th key={item} scope="col" className='fw-600'>
                                                            {CamelCaseWithSpaces(columnName)}
                                                        </th>
                                                })}
                                                <th scope="col" className=' fw-600 text-center'>Action <img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {CloseTicketData?.length != 0 ? (
                                                CloseTicketData?.map((item, i) => {
                                                    return (
                                                        <tr key={i} className={`OddEvenClass tableBorderHide ${GridTabs.CloseTicketTab == 'active' && (item.chargeability == 1 ? 'theme-color-batch' : '')}  ${item.InvoiceSent == 1 ? 'grayColor' : ''}`}>
                                                            {GridTabs.CloseTicketTab == 'active' && (
                                                                <td className="py-12">
                                                                    <input
                                                                        className='form-check-input'
                                                                        type="checkbox"
                                                                        checked={SelectedIdsForPending.includes(item.id)}
                                                                        onChange={(e) => handleCheckboxForPending(e, item.id)}
                                                                    />
                                                                </td>
                                                            )}


                                                            {GridTabs.ResolvedTicketTab == 'active' && (
                                                                <td className="py-12">
                                                                    {item?.invoice?.[0]?.invoice_pdf && (
                                                                        <input
                                                                            className='form-check-input'
                                                                            type="checkbox"
                                                                            checked={SelectedIdsForResolved.includes(item?.latestInvoice?.id)}
                                                                            onChange={(e) => handleCheckboxForResolved(e, "resolved", item?.latestInvoice?.id)}
                                                                        />
                                                                    )}
                                                                </td>
                                                            )}

                                                            {columnNamesForCloseTickets?.map(header => (
                                                                <td className="py-12" key={header}>
                                                                    {renderContentForColumn(header, item)}
                                                                </td>
                                                            ))}
                                                            <td className='py-12'>
                                                                {GridTabs.CloseTicketTab == 'active' ? (
                                                                    <img
                                                                        src="/images/InvoiceBlackIcon.svg"
                                                                        className="px-1 cursor-pointer"
                                                                        title="Generate Invoice"
                                                                        onClick={(e) => { navigate('/CloseTicketInvoice', { state: { Ticketid: item.id } }); }}
                                                                    />
                                                                ) : null}

                                                                {GridTabs.ResolvedTicketTab === 'active' && (
                                                                    <div className='row'>
                                                                        <div className='col-3 text-center'>
                                                                            {item?.invoice.length == 0 ?
                                                                                <img
                                                                                    src="/images/TransactionIcon.svg"
                                                                                    className="px-1 cursor-pointer"
                                                                                    title="Reopen Ticket"
                                                                                    onClick={() => {
                                                                                        setResolveTicketModal(true);
                                                                                        setTicketParams({ ...TicketParams, ticketId: item.id });
                                                                                    }}
                                                                                    height="22px"
                                                                                />
                                                                                :
                                                                                <>-</>
                                                                            }
                                                                        </div>
                                                                        <div className='col-3 text-center'>
                                                                            {item?.invoice?.[0]?.invoice_pdf ?
                                                                                <img
                                                                                    src="/images/previewpdficon.svg"
                                                                                    className="px-1 cursor-pointer"
                                                                                    title="Preview Invoice"
                                                                                    onClick={() => PreviewInvoiceHandle(item?.latestInvoice?.invoice_pdf, item?.latestInvoice?.id)}
                                                                                    style={{ height: '22px' }}
                                                                                />
                                                                                :
                                                                                <>-</>
                                                                            }
                                                                        </div>
                                                                        <div className='col-3 text-center'>
                                                                            {item?.invoice[0]?.paid_status == 1 ? (
                                                                                <FontAwesomeIcon icon={faStamp} title="Paid" className='' style={{ height: '20px', color: '#2cc3a9' }} />
                                                                            ) : (
                                                                                <FontAwesomeIcon icon={faStamp} title="Mark as paid" className='cursor-pointer' style={{ height: '20px', color: '#db3b3b' }} onClick={() => {
                                                                                    setPaymentModal(true);
                                                                                    setTicketParams({ ...TicketParams, ticketId: item.id });
                                                                                }} />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                }))
                                                :
                                                <tr><td colSpan={15} className="p-3 text-center"> No Record Found </td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {showPagination1 && (
                                    <Pagination activePage={currentPage1} totalItemsCount={pageRangeCount1}
                                        pageRangeDisplayed={15} itemsCountPerPage={LimitForPagination1} onChange={(e) => setCurrentPage1(e)}
                                        color="primary" lastPageText="" firstPageText="" itemClassFirst="MasterClass" itemClassPrev="MasterClass"
                                        itemClassNext="MasterClass" itemClassLast="MasterClass" />
                                )}
                            </>
                            :
                            <></>
                        }

                        {GridTabs.BatchesTabs == 'active' || GridTabs.PastBatchesTabs == 'active' ?
                            <>
                                <div className="data-table-container">
                                    <table className="table data-table">
                                        <thead className='GridHeader'>
                                            <tr>
                                                {GridTabs.BatchesTabs == 'active' && (
                                                    <th scope="col">
                                                        {setSchoolInvoiceData.filter(item => item.type != 2 && item.type != null).length != 0 ? (
                                                            <input className='form-check-input' type="checkbox" checked={selectedIdsAll.length == setSchoolInvoiceData.filter(item => item.type != 2 && item.type != null).length} onChange={(e) => handleHeaderCheckboxChange(e, "all")} />
                                                        ) : <th></th>}
                                                    </th>
                                                )}
                                                {columnNamesForBilling?.map((item) => {
                                                    const columnName = item.replace(/_/g, ' ');
                                                    return (
                                                        item != "Payment_Status" ?
                                                            <th key={item} scope="col" className='cursor-pointer fw-600' title={`Sort by ${columnName}`} onClick={(e) => handleSortClick(item)}>
                                                                {CamelCaseWithSpaces(columnName)}
                                                                <img src="/images/sortbynew.svg" alt={`Sort by ${columnName}`} className="img-fluid ps-2 sortbyClass" />
                                                            </th>
                                                            :
                                                            <th key={item} scope="col" className='fw-600'>
                                                                {CamelCaseWithSpaces(columnName)}
                                                            </th>
                                                    );
                                                })}
                                                {GridTabs.BatchesTabs == 'active' ?
                                                    <th scope='col' className='fw-600'>Receive</th>
                                                    :
                                                    <th scope='col' className='fw-600'>Receipt</th>
                                                }
                                                <th scope="col" className='fw-600'>Action<img src="/images/EditHeaderIcon.svg" className="cursor-pointer img-fluid ps-2 pe-5" title='Customize Headers' style={{ height: '25px' }} onClick={(e) => setCustomizationModal(true)} /></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {setSchoolInvoiceData?.length != 0 ? (
                                                setSchoolInvoiceData?.map((item, i) => {
                                                    return (
                                                        <tr key={i} className={`${item.type == 2 && GridTabs.BatchesTabs == 'active' ? 'redText' : ''} OddEvenClass tableBorderHide`}>
                                                            {GridTabs.BatchesTabs == 'active' && (
                                                                <td className="py-12">
                                                                    {item.type != 2 && item.type != null ? (
                                                                        <input
                                                                            className='form-check-input'
                                                                            type="checkbox"
                                                                            checked={selectedIdsAll.includes(item.id)}
                                                                            onChange={(e) => handleCheckboxChange(e, "all", item.id)}
                                                                            batchid={item.id}
                                                                        />
                                                                    ) : <></>}
                                                                </td>
                                                            )}
                                                            {columnNamesForBilling?.map(header => (
                                                                <td className="py-12" key={header}> {renderContentForColumn(header, item)} </td>
                                                            ))}
                                                            <td className='py-12'>
                                                                {item.type != null ? (
                                                                    item?.invoice[0]?.payment_status == 3 ? (
                                                                        <img
                                                                            src="/images/DownloadReceipt.svg"
                                                                            className="px-2 cursor-pointer"
                                                                            title="Download Receipt"
                                                                            onClick={() => downloadReceipt(item?.invoice[0]?.id)}
                                                                            height="22px"
                                                                        />
                                                                    ) : item.invoice[0]?.cheque_no == null ? (
                                                                        <img
                                                                            src="/images/TransactionIcon.svg"
                                                                            className="px-2 cursor-pointer"
                                                                            title="Receive and mark as paid"
                                                                            onClick={(e) => {
                                                                                setReponseForLink(item?.invoice[0]?.payment_link)
                                                                                setBatchAndInvoiceId({ ...BatchAndInvoiceId, BatchId: item.id, InvoiceNum: item?.invoice[0]?.id, BatchName: item.name, BatchType: item.type });
                                                                                setInvoiceModal(true);
                                                                                setSignatureDataForReceivedByGrid(item.batch_receiver_signature);
                                                                                setCheckno("");
                                                                                setChequeNoFlag(item?.invoice[0]?.payment_link ? 3 : 2)
                                                                            }}
                                                                            height="22px"
                                                                        />
                                                                    ) : (
                                                                        <span className="px-2 cursor-pointer">{item.invoice[0]?.cheque_no}</span>
                                                                    )) : <>-</>}
                                                            </td>
                                                            <td className="py-2">
                                                                <img src="/images/extraDocumenticon.svg" className="cursor-pointer" title="Preview Attached Document" onClick={(e) => ExtraDocument(item.id)} style={{ width: '15px' }} />
                                                                <img src="/images/printlabelicon.svg" title="Show Packing List" className="img-fluid cursor-pointer ps-2" onClick={(e) => PrintPackingList(item.id)} style={{ height: 18 }} />
                                                                {item?.invoice[0]?.invoice_pdf != null && (<img src="/images/previewpdficon.svg" className="pe-2 px-2 cursor-pointer" title="Preview Invoice" onClick={(e) => window.open(BucketS3Link + item?.invoice[0]?.invoice_pdf, '_blank')} />)}
                                                            </td>
                                                        </tr>
                                                    )
                                                }))
                                                :
                                                <tr><td colSpan={15} className="p-3 text-center"> No Record Found </td></tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {showPagination && (
                                    <Pagination
                                        activePage={currentPage}
                                        totalItemsCount={pageRangeCount}
                                        pageRangeDisplayed={15}
                                        itemsCountPerPage={LimitForPagination}
                                        onChange={(e) => setCurrentPage(e)}
                                        color="primary"
                                        lastPageText=""
                                        firstPageText=""
                                        itemClassFirst="MasterClass"
                                        itemClassPrev="MasterClass"
                                        itemClassNext="MasterClass"
                                        itemClassLast="MasterClass"
                                    />
                                )}
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </section>
            <Popup isshow={InvoiceModal} size={"md"} title={"Receive and mark as paid"}
                closePopup={(e) => { setInvoiceModal(false); setChequeNoFlag(0) }}
                modalBody={ReceiveModalBody} handleSave={SubmitpaymentDetails} visibleSaveBtn={true} btnText={"Send Notification"} />

            <AlertPopup isshow={ResolveTicketModal} img={'/images/greenInfo.svg'}
                headingtext={'Are you sure?'} text={`You want to reopen this ticket? This ticket will be shown in Ticket Invoicing.`}
                cancelcall={(e) => setResolveTicketModal(false)}
                canceltext={"No"} successtext={"Yes, Reopen it"} successcall={handleReopenTicket} btntype={'SaveBtn'} />
            <AlertPopup isshow={PaymentModal} img={'/images/greenInfo.svg'}
                headingtext={'Are you sure?'} text={`Would you like to mark this ticket as paid?`}
                cancelcall={(e) => setPaymentModal(false)}
                canceltext={"No"} successtext={"Yes, Mark it as paid!"} successcall={handlePayment} btntype={'SaveBtn'} />


            {CustomizationModal && <CustomizationPopup isShow={CustomizationModal} setCustomizationModal={setCustomizationModal} gridname={'Billing'} tabFlag={GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab ? '16' : '17'} GetCall={() => { GridTabs.CloseTicketTab == 'active' || GridTabs.ResolvedTicketTab == 'active' ? ApiGetHeaders(16, setColumnNamesForCloseTickets) : ApiGetHeaders(17, setColumnNamesForBilling) }} />}
            {UserModal && <UserDetailsPopup isShow={UserModal} ClosePopup={(e) => setUserModal(false)} userid={Userid} />}
            {isModal.isshowpopup && <DeviceDetailsPopup Showbuttons={1} ticketid={TicketParams.ticketId} createticketbutton={true} deviceid={TicketParams.inventoryId} activetab="device" isShow={isModal.isshowpopup} ClosePopup={(e) => setisModal({ ...isModal, isshowpopup: false })} />}
            {isModal.isTicketDetailspopup && <TicketDetailsPopup isShow={isModal.isTicketDetailspopup} ticketid={TicketParams.ticketId} inventoryid={TicketParams.inventoryId} ClosePopup={(e) => setisModal({ ...isModal, isTicketDetailspopup: false })} />}


            <AlertPopup isshow={ReceiveBatchModal} img={'/images/greenInfo.svg'}
                headingtext={`By confirming 'Yes,' the original devices will be returned to the users, and all the tickets under this batch will be marked as Closed. Are you sure you want to proceed?`}
                text={<div className="greyBox mt-4">
                    <div className='fw-600 font-15 text-center'>Received By Signature:</div>
                    <div className='mt-2 text-center'>
                        {SignatureDataForReceivedBy ? (
                            <img src={SignatureDataForReceivedBy} alt="Received By Signature" />
                        ) : (
                            <SignatureCanvas
                                ref={sigCanvasForReceivedBy}
                                canvasProps={{ width: 430, height: 100, className: "signatureCanvas" }}
                            />
                        )}
                    </div>
                    <div className="text-end"> <label className="cursor-pointer" onClick={clearSignatureForReceivedBy}><u>Clear</u></label></div>
                </div>}
                cancelcall={(e) => { setReceiveBatchModal(false); setSelectedIdsAll([]); }}
                canceltext={"Cancel"} successtext={"Yes"} successcall={(e) => { ReceiveBatchCall(e); }} btntype={'SaveBtn'} />

            <AlertPopup isshow={ResolvedModal} img={'/images/greenInfo.svg'}
                headingtext={`If you select 'Yes,' all tickets will be marked as Resolved. Are you sure you want to proceed?`} text={''}
                cancelcall={(e) => { setResolvedModal(false); setSelectedIdsForPending([]); }}
                canceltext={"Cancel"} successtext={"Yes"} successcall={(e) => ResolvedTicketCall(e)} btntype={'SaveBtn'} />

        </Fragment >
    )
}
